<template>
    <div class="z-50" id="nav_main">
      <div class="bg-black">
        <div class="relative navpos">
          <div class="mode-center">
            <div class="text-black" v-show="hamburger">
              <button class="py-3 px-6 focus:outline-none hover:bg-white hover:text-cherry" @click="menu_toggle" v-show="!menu_open">
                <svg class="w-10 h-10" fill="none" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
              </button>
              <button class="py-3 px-6 focus:outline-none hover:bg-white hover:text-cherry" @click="menu_toggle" v-show="menu_open">
                <svg class="w-10 h-10" fill="none" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
              </button>
            </div>
            <div class="flex flex-wrap mx1280:pb-6" id="nav_wrap">
              <router-link
                v-for="(item, index) in data" :key="'navitem_' + index"
                @click.native="nav_click(item.slug)"
                :to="item.slug"
                class="text text-white font-serif textnav py-1 px-6 marginnav hover:bg-white hover:text-cherry duration-300 ease-in-out focus:outline-none"
              >
                {{item.title}}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)



var lv = {}
lv.thisVue = null

export default {
  name: "nav_main",
  props: [],
  data() {
    return {
      data:{},
      menu_open: false,
      hamburger: false
    }
  },
  mounted() {
    lv.thisVue = this

    this.data = globals.api_data.first_contact.nav_items

    window.addEventListener('resize', function(event){
      lv.thisVue.resizer()
    })
    this.resizer()

    ScrollTrigger.create({
      trigger: "#nav_main",
      start: "top top", 
      pinSpacing: false,
      end: '+=999999',
      pin: "#nav_main"
    })

    /*
    gsap.to("#navlogo", {
      scrollTrigger: {
        scrub: true,
        start: "0",
        end: "900"
      },
      rotation: 180,
      ease: "none"
    })
    */

  },
  methods:{
    resizer(){
      if(window.innerWidth >= 1280){
        gsap.set('#nav_wrap',{display:'flex'})   
        this.menu_open = true
        this.hamburger = false
      }
      if(window.innerWidth <= 1279){
        gsap.set('#nav_wrap',{display:'none'}) 
        this.menu_open = false  
        this.hamburger = true
      }
    },
    menu_toggle(){
      if(this.menu_open){
        this.menu_open = false
        gsap.set('#nav_wrap',{display:'none'})
      }else{
        this.menu_open = true
        gsap.set('#nav_wrap',{display:'flex'})
      }
    },
    nav_click(slug){
      EventBus.$emit('nav_click',slug)
    }
  }
}
</script>

<style>
  .navpos{
    left:-20px;
  }
  .marginnav{
    margin-right: 50px;
  }
</style>

