import Vue from 'vue'
import VueRouter from 'vue-router'
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import( /* webpackChunkName: "main" */ '@/views/home.vue')
  },
  {
    path: '/cookie',
    name: 'cookie',
    component: () => import( /* webpackChunkName: "main" */ '@/views/cookie_.vue')
  },
  {
    path: '/:anchor',
    name: 'home-sub',
    component: () => import( /* webpackChunkName: "main" */ '@/views/home.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  /*
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      //globals.scrollSaved = savedPosition
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 };
    }
  }
  */
})


export default router
