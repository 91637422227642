<template>
  <div id="app" class="pos-r minh100v">

    <div class="pos-g bg-creme"></div>
    <div class="pos-r minh100v">

      <!-- 
        <div class="hdr" style="height:990px;"></div> 
      -->
      <header_main2 />
      <nav_main />
      
      <div class="minhview">
        <!-- 
        <transition
          appear
          :css="false"
          mode="out-in"
          @appear="appear"
          @enter="enter"
          @leave="leave"
        >

          <router-view :key="$route.fullPath" /> 

        </transition> 
        -->
        <router-view />
      </div>

      <footer_main />


      <!--  
       <div class="pos-g pointer-events-none text-center" style="opacity:0.2;">
        <img src="@/assets/img/temp/1.png" class="inline-block" style="width:100%; max-width:1440px;" />
      </div>
      <div class="pos-g pointer-events-none text-center" style="opacity:0.2;">
        <img src="@/assets/img/temp/2.jpg" class="inline-block" style="width:100%; max-width:1440px;" />
      </div>
      <div class="pos-g pointer-events-none text-center" style="opacity:0.2; top:4986px;">
        <img src="@/assets/img/temp/3.jpg" class="inline-block" style="width:100%; max-width:1440px;" />
      </div>
      <div class="pos-g pointer-events-none text-center" style="opacity:0.2; top:7107px;">
        <img src="@/assets/img/temp/4.jpg" class="inline-block" style="width:100%; max-width:1440px;" />
      </div>
     -->


    </div>

    <!--        
    <div class=" pointer-events-none text-center" style="opacity:0.2; position:fixed; left:0px; width:100%; bottom:0px;">
      <img src="@/assets/img/temp/foot.jpg" class="inline-block" style="width:100%; max-width:1440px;" />
    </div>
    -->

    <preload />
    <sneekpreview v-if="preview_show" />


    <div class="pos-g bg-white hidden opacity-0" id="hider_main"></div>
  </div>
</template>


<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'

import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
const scrollPlugin = ScrollToPlugin
gsap.registerPlugin(ScrollToPlugin)

import preload from '@/components/preload.vue'
import sneekpreview from '@/components/sneekpreview.vue'
import header_main2 from '@/components/ui/header_main2'
import nav_main from '@/components/ui/nav_main'
import footer_main from '@/components/ui/footer_main'

var lv = {}
lv.thisVue = null

export default {
  name: "app",
  components: {
    preload,
    sneekpreview,
    header_main2,
    nav_main,
    footer_main,
  },
  data() {
    return {
      preview_show: false,
      show_live_update: false
    }
  },
  mounted () {

    lv.thisVue = this

    if(globals.preview_allowed){
      control_main.preloader_done()
    }else{
      this.preview_show = true
    }

    EventBus.$on('live_update', (e) => {
      if(this.show_live_update){
        this.show_live_update = false
      }else{
        this.show_live_update = true
      }
    })

    // hijack a href to router
    /*
    window.addEventListener('click', event => {
      let { target } = event
      while (target && target.tagName !== 'A') target = target.parentNode
      if (target && target.matches("a:not([href*='://'])") && target.href) {
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
        if (metaKey || altKey || ctrlKey || shiftKey) return
        if (defaultPrevented) return
        if (button !== undefined && button !== 0) return
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target')
          if (/\b_blank\b/i.test(linkTarget)) return
        }
        const url = new URL(target.href)
        const to = url.pathname
        if (window.location.pathname !== to && event.preventDefault) {
          event.preventDefault()
          this.$router.push(to)
        }
      }
    })
    */
  },
  methods: {
    appear(el, done) {
      gsap.to('#curtain_main',{duration:0.5, display: 'none', opacity: 0, ease:'power3.inOut', onComplete: done })
    },
    enter(el, done) {
      gsap.to('#curtain_main',{delay:0.2, duration: 0.5, display: 'none', opacity: 0, ease:'power3.inOut', onComplete: done })
    },
    leave(el, done) {
      gsap.to('#curtain_main',{duration:0.5, display: 'block', opacity: 1, ease:'power3.inOut', onComplete: done })
    },
  }
}


</script>

<style>

.minh100v{
  min-height: 100vh;
}
.minhview{
  min-height: 500px;
}

</style>
