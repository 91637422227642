import Vue from 'vue'
import app from './app.vue'
import router from './router'

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VuePlyr, { plyr: {} })

if (process.env.NODE_ENV === 'production') {
  console.log('%c Het Nieuwe Amsterdam Museum :: by multitude.nl ', 'background: #000000; color: #fff; padding:3px; font-size:15px;')
}

import "@/assets/styles/main.css"

Vue.config.productionTip = false

/* ---------------------------------------------------- */
// directives
Vue.directive('click-outside',
{
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
/* ---------------------------------------------------- */

function app_start(){
  new Vue({
    router,
    render: h => h(app)
  }).$mount('#app')
}

/*
app_start()
*/

import { EventBus } from "./modules/event_bus"
import control_main from './modules/control_main'
import globals from './modules/globals'

if(localStorage.getItem('consolify')){
  console.log('consolify active')
  globals.consolify = true
}

control_main.connect_first()

EventBus.$on('connect_first_success', (e) => {
  app_start()
})



