
export var globals = {
    host: 'http://localhost/',
    path_main: 'uploads/hetnieuweamsterdammuseum/originals/',
    consolify: false,
    connection_established: false,
    preview_allowed: false,
    api_data:{},
    kit_history: [{id:0, chosen_nr:0, dest_id:0, has_feedback:false}],
    embed: {
        youtube:{
            pre: 'https://www.youtube.com/embed/',
            post: '?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1'
        },
        vimeo:{
            pre: 'https://player.vimeo.com/video/',
            post: '?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media'
        },
    }
}

if(process.env.NODE_ENV === 'production'){
    globals.host = '/'
}
/*
*/

export default globals